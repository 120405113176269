<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="logo-fb-simple 1" clip-path="url(#clip0_36087_107026)">
      <g id="Group">
        <path
          id="Vector"
          d="M6.22064 15.1969L6.2 8.89687H3.5V6.19687H6.2V4.39687C6.2 1.9676 7.70436 0.796875 9.87144 0.796875C10.9095 0.796875 11.8017 0.874158 12.0616 0.9087V3.44744L10.5587 3.44812C9.38009 3.44812 9.15188 4.00816 9.15188 4.82999V6.19687H12.5L11.6 8.89687H9.15187V15.1969H6.22064Z"
          fill="#FCFCFC"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_36087_107026">
        <rect
          width="14.4"
          height="14.4"
          fill="white"
          transform="translate(0.800781 0.796875)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
